import React, { useEffect } from "react";

const appendInput = (form, key, value) => {
  const input = document.createElement("input");
  input.type = "hidden";
  input.name = key;
  input.value = value;
  form.appendChild(input);
};

const PostRedirectApex = ({ action, PaReq, MD, TermUrl }) => {
  console.info("Gate 12 debug:");
  console.info("action", action);
  console.info("PaReq", PaReq);
  console.info("MD", MD);
  console.info("TermUrl", TermUrl);

  useEffect(() => {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = action;
    form.style.display = "none";

    appendInput(form, "PaReq", PaReq);
    appendInput(form, "MD", MD);
    appendInput(form, "TermUrl", TermUrl);

    document.body.appendChild(form);
    form.submit();

    return () => {
      document.body.removeChild(form);
    };
  }, [action, PaReq, MD, TermUrl]);

  return null;
};

export default PostRedirectApex;
