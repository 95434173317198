import React, { useEffect } from "react";

const appendInput = (form, key, value) => {
  const input = document.createElement("input");
  input.type = "hidden";
  input.name = key;
  input.value = value;
  form.appendChild(input);
};

const PostRedirectPaygine = (props) => {
  const { action, PaReq, MD, TermUrl, creq } = props;

  useEffect(() => {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = action;
    form.style.display = "none";

    if (creq) {
      appendInput(form, "creq", creq);
    } else if (PaReq) {
      appendInput(form, "PaReq", PaReq);
      appendInput(form, "MD", MD);
      appendInput(form, "TermUrl", TermUrl);
    } else console.log("Ни того ни другого 🤷‍♂️");

    document.body.appendChild(form);
    form.submit();

    return () => {
      document.body.removeChild(form);
    };
  }, [action, PaReq, MD, TermUrl, creq]);

  return null;
};

export default PostRedirectPaygine;
