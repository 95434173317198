import React, { useEffect } from "react";

const appendInput = (form, key, value) => {
  const input = document.createElement("input");
  input.type = "hidden";
  input.name = key;
  input.value = value;
  form.appendChild(input);
};

const PostRedirectHalyk = ({ action, TermUrl, PaReq, MD }) => {
  console.log({ TermUrl, PaReq, MD });

  useEffect(() => {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = action;
    form.style.display = "none";

    appendInput(form, "PaReq", PaReq);
    appendInput(form, "MD", MD);
    appendInput(form, "TermUrl", TermUrl);

    console.log(form);

    document.body.appendChild(form);
    form.submit();

    return () => {
      document.body.removeChild(form);
    };
  }, [action, TermUrl, PaReq, MD]);

  return null;
};

export default PostRedirectHalyk;
