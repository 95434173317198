import React, { useEffect } from "react";

const appendInput = (form, key, value) => {
  const input = document.createElement("input");
  input.type = "hidden";
  input.name = key;
  input.value = value;
  form.appendChild(input);
};

const PostRedirect = ({ url, creq, threeDSSessionData }) => {
  console.log({ url, creq, threeDSSessionData });

  useEffect(() => {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = url;
    form.style.display = "none";

    appendInput(form, "creq", creq);
    appendInput(form, "threeDSSessionData", threeDSSessionData);
    console.log(form);

    document.body.appendChild(form);
    form.submit();

    return () => {
      document.body.removeChild(form);
    };
  }, [url, creq, threeDSSessionData]);

  return null;
};

export default PostRedirect;
